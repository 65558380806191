exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-current-dose-js": () => import("./../../../src/pages/current-dose.js" /* webpackChunkName: "component---src-pages-current-dose-js" */),
  "component---src-pages-goals-2-js": () => import("./../../../src/pages/goals-2.js" /* webpackChunkName: "component---src-pages-goals-2-js" */),
  "component---src-pages-goals-3-js": () => import("./../../../src/pages/goals-3.js" /* webpackChunkName: "component---src-pages-goals-3-js" */),
  "component---src-pages-goals-4-js": () => import("./../../../src/pages/goals-4.js" /* webpackChunkName: "component---src-pages-goals-4-js" */),
  "component---src-pages-goals-5-js": () => import("./../../../src/pages/goals-5.js" /* webpackChunkName: "component---src-pages-goals-5-js" */),
  "component---src-pages-goals-js": () => import("./../../../src/pages/goals.js" /* webpackChunkName: "component---src-pages-goals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-js": () => import("./../../../src/pages/information.js" /* webpackChunkName: "component---src-pages-information-js" */),
  "component---src-pages-introduction-js": () => import("./../../../src/pages/introduction.js" /* webpackChunkName: "component---src-pages-introduction-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-results-2-js": () => import("./../../../src/pages/results-2.js" /* webpackChunkName: "component---src-pages-results-2-js" */),
  "component---src-pages-results-js": () => import("./../../../src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */),
  "component---src-pages-sendemail-js": () => import("./../../../src/pages/sendemail.js" /* webpackChunkName: "component---src-pages-sendemail-js" */),
  "component---src-pages-soda-extra-2-js": () => import("./../../../src/pages/soda-extra-2.js" /* webpackChunkName: "component---src-pages-soda-extra-2-js" */),
  "component---src-pages-soda-extra-js": () => import("./../../../src/pages/soda-extra.js" /* webpackChunkName: "component---src-pages-soda-extra-js" */),
  "component---src-pages-soda-js": () => import("./../../../src/pages/soda.js" /* webpackChunkName: "component---src-pages-soda-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-use-and-cravings-1-js": () => import("./../../../src/pages/use-and-cravings-1.js" /* webpackChunkName: "component---src-pages-use-and-cravings-1-js" */),
  "component---src-pages-use-and-cravings-2-js": () => import("./../../../src/pages/use-and-cravings-2.js" /* webpackChunkName: "component---src-pages-use-and-cravings-2-js" */),
  "component---src-pages-use-and-cravings-3-js": () => import("./../../../src/pages/use-and-cravings-3.js" /* webpackChunkName: "component---src-pages-use-and-cravings-3-js" */),
  "component---src-pages-withdrawal-1-js": () => import("./../../../src/pages/withdrawal-1.js" /* webpackChunkName: "component---src-pages-withdrawal-1-js" */),
  "component---src-pages-withdrawal-2-js": () => import("./../../../src/pages/withdrawal-2.js" /* webpackChunkName: "component---src-pages-withdrawal-2-js" */),
  "component---src-pages-withdrawal-3-js": () => import("./../../../src/pages/withdrawal-3.js" /* webpackChunkName: "component---src-pages-withdrawal-3-js" */),
  "component---src-pages-withdrawal-4-js": () => import("./../../../src/pages/withdrawal-4.js" /* webpackChunkName: "component---src-pages-withdrawal-4-js" */)
}

