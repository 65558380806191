import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../Firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";

const defaultContext = {
  currentUser: null,
  sodaResults: {
    date: "",
    user: "",
    currentDose: {
      type: false,
      frequency: false,
      amount: false,
      supervised: false,
      supervisedDoses: false,
      takeawayDoses: false,
      pickupDoses: false,
      missedDoses: false,
      prescriptionDuration: false,
      treatmentLength: false,
      prescriptionDescription: false,
    },
  },
};

export const AuthContext = createContext(defaultContext);
export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sodaResults, setSodaResults] = useState({
    date: "",
    user: "",
    currentDose: {
      type: false,
      frequency: false,
      amount: false,
      supervised: false,
      supervisedDoses: false,
      takeawayDoses: false,
      pickupDoses: false,
      missedDoses: false,
      prescriptionDuration: false,
      treatmentLength: false,
      prescriptionDescription: false,
    },
    goals: [],
  });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);

      if (user) {
        localStorage.setItem("soda_logged_in", user.uid);
      }
    });

    if (localStorage.getItem("soda_results")) {
      setSodaResults(JSON.parse(localStorage.getItem("soda_results")));
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        sodaResults,
        setSodaResults,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthValue() {
  return useContext(AuthContext);
}

export default AuthProvider;
