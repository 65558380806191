import React from "react";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "semantic-ui-css/semantic.min.css";
import "./src/style/style.scss";

import AuthProvider from "./src/components/Auth/authContext";

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
);
